<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Employee</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Employee List</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row  class="mb-2">
              <b-col md="8"></b-col>
              <b-col md="2"><base-button block type="primary" @click="$router.push({name: 'add-employee'})" >Add Employee</base-button></b-col>
              <b-col md="2"><base-button block type="primary" @click="csvExport(csvData)">Export to CSV</base-button></b-col>
            </b-row>
            <b-card type="default" no-body>
                <vue-good-table
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :search-options="{
                enabled: true,
                placeholder: 'Search this table',
                }"
                :pagination-options="{
                enabled: true,
                mode: 'records'
                }"
                >
                  <template slot="table-row" slot-scope="props" style="w">
                    <span v-if="props.column.field == 'after'">
                        <base-button
                        icon type="primary"
                        size="sm"
                        @click="$router.push({name: 'add-employee',params:{empId:props.row.employee_id}})"
                        >
                        <span class="btn-inner--icon"><i class="far fa-edit"></i></span>
                        </base-button>
                        <base-button
                        icon type="danger"
                        size="sm"
                        @click="checkedClick(props.row.employee_id)"
                        >
                        <span class="btn-inner--icon">
                          <i class="ni ni-check-bold" v-if="props.row.employee_active"></i>
                          <i class="ni ni-fat-remove" v-else></i>
                        </span>
                        </base-button>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
            </b-card>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api';
export default {
  name: 'service-list',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: true,
      columns: [
        {
          label: 'Name',
          field: 'employee_name'
        },
        {
          label: 'Email',
          field: 'employee_email'
        },
        {
          label: 'Phone No',
          field: 'employee_phone',
          type: 'number'
        },
        {
          label: 'Employee Type',
          field: 'employee_role'
        },
        {
          label: 'Employee Location',
          field: 'location'
        },
        {
          label: 'Employee Dob',
          field: 'employee_dob'
        },
        {
          label: 'Address',
          field: 'employee_address'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ] 
    }
  },
  computed: {
    csvData () {
      return this.rows.map(item => ({
        ...item
      //  address: 'адрес', // item.address.city,
      //  company: 'компания'// item.company.name
      }))
    },
    rows () {
      return (this.$store.state.employeeList === null ? [] : this.$store.state.employeeList)
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.employee_id === id) {
          row.employee_active = !row.employee_active; 
          let active = row.employee_active;
          this.axios.post(API_URL+'admin_employee_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
                this.$swal.fire({
                  icon: 'success',
                  title: 'Oops...',
                  text: `${message}`,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                })
            }else {
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              })
            }
          })
        }
        return row
      })
    },
    csvExport (arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(arrData[0]).join(';'),
        ...arrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'employee-list.csv')
      link.click()
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
</style>